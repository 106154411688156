import zerobarrier from '../assets/zerobarrier.png';
import zerobarrierzoom from '../assets/zerobarrierzoom.jpg';
import softskillsteam from '../assets/softskillsteam.jpg';
import centeo from '../assets/centeo.jpg';
import vitimob from '../assets/vitimob.jpg';
import participation4 from '../assets/participation4.jpg';
import valorg from '../assets/valorg.jpg';
import softskills from '../assets/softskills.jpg';
import inkluster from '../assets/inkluster.jpg';
import certiorg from '../assets/certi-org.jpg';
import erasmusplus from '../assets/erasmusplus.jpg';
import hva from '../assets/hva.jpg';
import certifia from '../assets/certipad.jpg';
import luculus from '../assets/luculus.jpg';
import rdvlocavores from '../assets/rdvlocavores.jpg';
import agrieventh from '../assets/agrieventh.jpg';
import equal from '../assets/equal.png';
import interreg from '../assets/interreg.jpg';
import lucus from '../assets/lucus.jpg';
import terra from '../assets/terra.jpg';
import equideha from '../assets/equideha.jpg';
import legaqual from '../assets/legaqual.jpg';
import logograndest from '../assets/logograndest.jpg';
import logoeurope from '../assets/logoeurope.jpg';
import logomainverte from '../assets/logomainverte.jpg';
import softskills2 from '../assets/softskills2.png';

// eslint-disable-next-line
export default [
    {
        id: 0,
        url: "",
        name: "Soft skills 2.0",
        activity: "Projet Erasmus +",
        slug: "softskills2",
        image: softskills2,
        details: {
            project_name: "Soft skills 2.0",
            programme: "Erasmus +",
            period: "du 01/12/2021 au 01/11/2023",
            duration: "24 mois",
            web: "https://www.facebook.com/SoftSkillsEU",
            carrier: "La Régie des Écrivains",
            partners:
                "France: Solivers" +
                "\n" +
                "France: La Régie des Ecrivains" +
                "\n" +                 
                "Belgique: Le Plope" +
                "\n" +
                "Belgique: Work'Inn" +
                "\n" +
                "Autriche: Chance B" +
                "\n" +
                "Slovénie: CUDV DRAGA" +
                "\n" +
                "Espagne: Peñascal",
            budget: "",
            images: [
                {id: 0, legend: "Logo de softskills", photo: softskills2, alt: "logo de softskills"},
                {id: 1, legend: "Séminaire de Liège", photo: softskillsteam, alt: "logo de Zerobarrier"},
            ],
            excerpt:
                "Le projet Soft skills 2.0 est un projet européen Erasmus+ d’une durée de 24 mois (décembre 2021 – novembre 2023) regroupant 7 entreprises sociales et associations issus de 5 pays européens : la Belgique, l’Espagne, l’Autriche, la Slovénie et la France." +
                "\n" +
                "Il est la suite logique du projet Erasmus + Soft skills qui s'est déroulé de janvier 2018 à décembre 2021 et qui visait à accompagner les parcours de réussite, en agissant notamment sur les compétences et qualités comportementales (soft skills) des personnes de bas niveau de qualification et/ou en situation de handicap." +
                "\n" +
                "Ainsi, ce premier projet a permis de développer un référentiel comprenant 20 soft skills, un programme de formation en salle nivelé en 4 niveaux, un protocole de positionnement et d'évaluation à destination des personnes éloignées de l'emploi et/ou en situation de handicap et une formation à destination des formateurs et tuteurs pour leur permettre de s'emparer des outils mentionnés." +
                "\n" +
                "Grace au projet Soft skills 2.0, les partenaires aimeraient pouvoir aller encore plus loin en développant un programme de formation en situation de travail ainsi que des outils et méthodes pour intégrer les soft skills dans les processus de recrutement et de management des entreprises européennes." +
                "\n" +
                "Solivers et ses 6 partenaires français, belges, autrichiens, slovènes et espagnols se sont donc donnés pour objectif de créer une méthode et des outils pédagogiques partagés pour permettre d'avoir une offre de service Soft skills complète à destination des personnes éloignées de l'emploi et/ ou en situation de handicap ET des entreprises qui souhaiteraient intégrer les soft skills dans leur culture d'entreprise." 

        }
    },
    {
        id: 1,
        url: "",
        name: "Zero Barrier",
        activity: "Programme Léonardo Da Vinci.",
        slug: "Zero-Barrier",
        image: zerobarrier,
        details: {
            project_name: "Zero Barrier",
            programme: "Leonardo Da vinci" +
                "\n" +
                " Programme pour l'éducation et la formation tout au long de la vie",
            period: "du 01/10/2013 au 01/10/2015",
            duration: "24 mois",
            web: "https://zerobarrier.app",
            carrier: "AVS Traiteur",
            partners:
                "Belgique: WORK INN Asbl" +
                "\n" +
                "France: B4Com" +
                "\n" +
                "Slovénie: CUDV DRAGA" +
                "\n" +
                "Italie: I.I.S. Professional" +
                "\n" +
                "Espagne: Servicios Integrales de Contracion e Intermediacion Dominus S.L." ,
            budget: "333 777 €",
            images: [
                {id: 0, legend: "Logo ZeroBarrier", photo: zerobarrierzoom, alt: "logo de Zerobarrier"},
            ],
            excerpt:
                "Le projet initial : \n"+
                "AVS Traiteur est une entreprise adaptée qui travaille pour l'inclusion de personnes en situation de handicap dans les métiers de bouche. \n"+
                "\n"+
                "Avec des partenaires de cinq pays européens (France, Belgique, Italie, Slovénie, Espagne), Le Relais AVS est porteur du projet européen \"Zero Barrier\" qui s'inclut dans le programme Leonardo Da Vinci (Programme pour l'éducation et la formation tout au long de la vie). \n"+
                "\n"+
                "Les objectifs de ce dispositif sont de favoriser l'accès à la certification dans les métiers de la restauration ppour les personnes en situation de handicap en développant une pédagogie adaptée. \n"+
                "Un des moyens pour y arriver est la création, en partenariat avec l'association B4Com, de codes visuo-gestuels permettant de lever les différents obstacles dues à des problèmes de communication en situation professionnelle (travailleurs sourds, ambiance sonore élevée, barrière linguistique,...). Ces codes visuo-gestuels se distinguent de la langue des signes par le fait qu'ils sont internationaux, simples, intuitifs et réduits au minimum nécessaire aux savoir-faire spécifiques au métier. \n"+
                "\n"+
                "L'évolution du projet : \n"+
                "Ce programme débuté sur les métiers de bouche a donné lieu à la solution digitale ZERO BARRIER © un dispositif numérique innovant : \n"+
                "·        Basé sur des référentiels de certifications professionnelles nationales : référentiels métier, Soft Skills, CLEA \n"+
                "·        Utilisé par les professionnels (employeurs, organismes de formation...) et les apprenants - Pour tracer les parcours de compétence notamment en situation de travail \n"+
                "·        Les évaluations sont basées sur des éléments constatifs, pour une mesure la plus objective \n"+
                "·        Permettant de concevoir et visualiser les parcours et d'éditer les éléments de valorisation (attestation de compétences, passeport métier…)\n",
                
        }       
    },
    {
        id: 2,
        url: "",
        name: "Soft skills",
        activity: "Projet Erasmus +",
        slug: "softskills",
        image: softskills,
        details: {
            project_name: "Soft skills",
            programme: "Erasmus +",
            period: "du 01/09/2018 au 31/08/2021",
            duration: "36 mois",
            web: "",
            carrier: "La Régie des Écrivains",
            partners:
                "France: Solivers" +
                "\n" +
                "France: La Régie des Ecrivains" +
                "\n" +
                "France: Les Z'ailes" +
                "\n" +
                "Belgique: Le Plope" +
                "\n" +
                "Belgique: Work'Inn" +
                "\n" +
                "Autriche: Chance B" +
                "\n" +
                "Slovénie: CUDV DRAGA" +
                "\n" +
                "Espagne: Peñascal" +
                "\n" +
                "Espagne: Asociación Española de Escuelas de Segunda Oportunidad",
            budget: "417 765 €",
            images: [
                {id: 0, legend: "Logo de softskills", photo: softskills, alt: "logo de softskills"},
                {id: 1, legend: "Séminaire de Liège", photo: softskillsteam, alt: "logo de Zerobarrier"},
            ],
            excerpt:
                "Soft skills est un projet européen Erasmus+ d'une durée de 36 mois (septembre 2018 – août 2021), porté par la Régie des Ecrivains. Il regroupe des entreprises sociales et associations de 5 pays européens : la Belgique, l'Espagne, l'Autriche, la Slovénie et la France." +
                "\n" +
                "Le projet Soft vise à accompagner les parcours de réussite, en agissant notamment sur les compétences et qualités comportementales des personnes de bas niveau de qualification et les personnes en situation de handicap." +
                "\n" +
                "Le développement des compétences comportementales est particulièrement important dans un contexte où le monde du travail est en train de changer profondément et où l’économie globale ne crée pas un nombre d’emploi suffisant.De plus en plus d’employeurs s’intéressent aux compétences comportementales de leurs salariés, et plus seulement aux compétences techniques.Ces compétences comportementales sont des qualités humaines, des traits de personnalité, des savoir - être." +
                "\n" +
                "Les 9 partenaires français, belges, autrichiens, slovènes et espagnols se sont donc donné pour objectif de créer une méthode et des outils pédagogiques partagés à destination des formateurs de la formation continue et des encadrants techniques, tuteurs de la formation en situation de travail."
        }
    },
    {
        id: 3,
        url: "",
        name: "Inkluster",
        activity: "Projet Leader/Region Grand Est AMI Initiatives Territoriales",
        slug: "inkluster",
        image: inkluster,
        details: {
            project_name: "Inkluster",
            programme: "Leader/Region Grand Est AMI Initiatives Territoriales",
            period: "du 15/03/2017 au 28/02/2021",
            duration: "36 mois",        
            web: "",
            carrier: "Solivers",
            partners: "",
            budget: "229 304 €",
            images: [
                {id: 0, legend: "Logo inkluster", photo: inkluster, alt: "logo de Inkluster"},
                {id: 1, legend: "Logo fond européen", photo: logoeurope, alt: "logo fond européen"},
                {id: 2, legend: "Logo Grand Est", photo: logograndest, alt: "Logo Grand Est"},
            ],
            excerpt:
            "Les objectifs d’INKLUSTER en Grand-Est et sur le territoire Bruche Mossig Piémont : \n" +
            "   * Démultiplier les actions structurantes et innovantes pour faciliter l’emploi de publics à besoins spécifiques. \n" +
            "   * Développer les secteurs visant des emplois « faisables » pour que déficience ne rime pas avec handicap. \n" +
            "   * Permettre la création d’emplois pérennes pour des personnes en situation de handicap. \n" +
            "   * Lever les barrières didactiques, organisationnelles, rendre ergonomique et accessible en créant des outils pédagogiques. \n \n" +            
            "Les actions du projet : \n" +          
            "   * Développement des activités nouvelles sur le territoire et accompagnement à la création de structures : \n" +
            "   * Ateliers valorisant les matières organiques : élevage d’insectes, combustibles, compost \n" +
            "   * Déploiement d’ateliers maraichage-permaculture \n" +
            "   * Développement de projets et offre de services locavores \n" +
            "   * Création d’Etireo pour la prévention des TMS en entreprise \n \n" +
            "Accompagnement vers l’emploi pour publics vulnérables sur le territoire : \n" +
            "   * Création de l’ingénierie adaptée \n" +
            "   * Déploiement du dispositif Zero Barrier auprès des structures du territoire par le développement des AFEST et de l’accompagnement à la certification des compétences des personnes en situation de handicap."
        }
    },
    {
        id: 4,
        url: "http://www.participation4.0.solivers.eu/Home/Projet",
        name: "Participation 4.0",
        activity: "Projet Interreg V Rhin Supérieur.",
        slug: "",
        image: participation4,
        details: {}     
    },
    {
        id: 5,
        url: "https://vinimob.eu",
        name: "Vitimob",
        activity: "Projet Erasmus +",
        slug: "",
        image: vitimob,
        details: {}
    },
    {
        id: 6,
        url: "",
        name: "Luculus",
        activity: "Projet Interreg",
        slug: "",
        image: luculus,
        details: {}
    },
    {
        id: 7,
        url: "http://www.ideealsace.com/",
        name: "Partenariat ave Haies Vives d'Alsace",
        activity: "Filière plants locaux.",
        slug: "",
        image: hva,
        details: {}
    },
    {
        id: 8,
        url: "",
        name: "Certi-Org",
        activity: "Programme Léonardo Da Vinci.",
        slug: "certi-org",
        image: certiorg,
        details: {
            project_name: "Certi-Org",
            programme: "Leonardo Da vinci \n Programme pour l'éducation et la formation tout au long de la vie",
            period: "du 01/10/2013 au 01/10/2015",
            duration: "24 mois",
            web: "",
            carrier: "AgroSup-Eduter",
            partners:
                "France: La Main Verte" +
                "\n" +
                "France: La Régie des Ecrivains" +
                "\n" +
                "France: CFPPA Obernai" +
                "\n" +
                "Allemagne: ASW+W" +
                "\n" +
                "Autriche: Chance B" +
                "\n" +
                "République Tchèque: CULS Prague" +
                "\n" +
                "République Tchèque: Prosaz" +
                "\n" +
                "Belgique: CFP Le Plope" +
                "\n" +
                "Espagne: Universidad Polytécnica de Madrid",
            budget: "397 472 €",
            images: [
                {id: 0, legend: "Logo certi-org", photo: certiorg, alt: "logo de certi-org"},
                {id: 1, legend: "Logo de la main verte", photo: logomainverte, alt: "logo la main verte"},
            ],
            excerpt:
                "Le projet Certi-Org a pour objectifs de:\n" +
                "\n" +
                "- Favoriser l'accès des personnes en situation de handicap à le certification de droit commun et lutter ainsi contre la discrimination.\n" +
                "\n" +
                "- Développer une pédagogie accessible au plus grand nombre et particulièrement aux personnes en situation de handicap ou à bas niveau de qualification.\n" +
                "Cela passe par une évolution des postures et des positionnements des acteurs de l'accompagnement (formateurs et tuteurs en entreprise) afin de s'adapter aux besoins spécifiques des apprenants, du public cible. Former les formateurs et les tuteurs (12 personnes / 5 pays).\n" +
                "\n" +
                "- Identifier des gestes professionnels et des situations expérientielles qui ont un potentiel d'apprentissage élevé et qui sont communes aux partenaires européens du projet tout en respectant les particularismes locaux et nationaux. Formation expérimentale: 20 à 30 personnes.\n" +
                "\n" +
                "- Harmoniser les pratiques professionnelles dans différents métiers et branches professionnelles en passant par l'analyse des situations expérientielles et de leur potentiel professionnel des 3 domaines TP, PC et SMR, commun à tous les partenaires mais tenant compte des spécificités de chacun.\n" +
                "\n" +
                "- Produire les outils et supports et réaliser les actions de formation, expérimentation, évaluation, élargissement: formation (90 à 100 personnes) basées sur les situations expérientielles, situations soumises à un processus d'évaluation-recherche.\n" +
                "\n" +
                "- Assurer la gestion collaborative du projet et la réalisation de ses objectifs avec suivi et évaluation interne."
        }
    },
    {
        id: 10,
        url: "",
        name: "Centeo",
        activity: "Combustible paille de maïs.",
        slug: "",
        image: centeo,
        details: {}
    },
    {
        id: 11,
        url: "",
        name: "Valorg",
        activity: "Programm Progress",
        slug: "valorg",
        image: valorg,
        details: {
            project_name: "Valorg",
            programme: "Programm Progress",
            period: "",
            duration: "",
            web: "",
            carrier: "",
            partners:
                "",
            budget: "",
            images: [{id: 0, legend: "Logo valorg", photo: valorg, alt: "logo de valorg"}],
            excerpt:
            "Trier les dechets pour mieux valoriser les dechets organiques: trois résulyats probants . \n"
            +
            "Dechets organiques compostables; mise en place d'un processus de compostage de produits agricoles et d'espaces verts, utilisés en tant qu unique intrant \n"
            +
            "pour nos jardins de fenoculture de Mittelbergheim et de Molsheim.\n"
            +
            "Production a partir des dechets de faible potentiel compost de pellets/cf programme Centeo et TERRA SYSTEMA .\n"
            +
            "L'usage des pellets est la production de chaleur dans les vergers sensibles aux gelées tardives."
        }
    },
    {
        id: 12,
        url: "https://info.erasmusplus.fr/",
        name: "LegalQual2Do",
        activity: "Programme Léonardo Da Vinci",
        slug: "",
        image: erasmusplus,
        details: {}
    },
    {
        id: 13,
        url: "",
        name: "Certifia",
        activity: "Outil de gestion des compétences",
        slug: "",
        image: certifia,
        details: {}
    },
    {
        id: 14,
        url: "",
        name: "Les rendez-vous Locavores",
        activity: "Micro-projet 4.23",
        slug: "rdvlocavores",
        image: rdvlocavores,
        details: {
            project_name: "Les rendez-vous Locavores",
            programme: "Micro-projet 4.23",
            period: "",
            duration: "",
            web: "",
            carrier: "",
            partners:
                "",
            budget: "",
            images: [{id: 0, legend: "Logo rdvlocavores", photo: rdvlocavores, alt: "logo de rdvlocavores"}],
            excerpt:             
            "Production d'outils de gestion d'une plateforme de commercialisation de produits alimentaires destinée aux petits producteurs \n"
            +
            "qui mutualisent leurs productions pour réaliser un changement d'échelle en matiére d'approvisionnement."
        }
    },
    {
        id: 15,
        url: "",
        name: "Certi-Rhin",
        activity: "Certi-Rhin",
        slug: "interreg",
        image: interreg,
        details: {
            project_name: "Certi-Rhin",
            programme: "Certi-Rhin",
            period: "",
            duration: "",
            web: "",
            carrier: "",
            partners:
                "",
            budget: "",
            images: [{id: 0, legend: "Logo interreg", photo: interreg, alt: "logo de interreg"}],
            excerpt:                         
            "Production d'outils didactiques et pedagogiques basés sur les réalités franco allemandes. \n"
            +
            "Production de certifications franco allemande dans les domaines maraichers, fruitiers, viticoles, et espaces verts, ces certifications sont déployées de part et d'autre du Rhin et accessibles à tous. \n"        
        }
    },
    {
        id: 16,
        url: "",
        name: "Agri-Eventh",
        activity: "Agri-Eventh",
        slug: "agrieventh",
        image: agrieventh,
        details: {
            project_name: "Agri-Eventh",
            programme: "Agri-Eventh",
            period: "",
            duration: "",
            web: "",
            carrier: "",
            partners:
                "",
            budget: "",
            images: [{id: 0, legend: "Logo agrieventh", photo: agrieventh, alt: "logo de agrieventh"}],
            excerpt:                                
            "Echange trinationale pour la promotion des produits alementaires issus de l'inclusion, programmes de formation accessibles a tous en matiere de promotion et de vente. \n"            
        }
    },
    {
        id: 17,
        url: "",
        name: "Equal Ressources",
        activity: "Equal Ressources",
        slug: "equal",
        image: equal,
        details: {
            project_name: "Equal Ressources",
            programme: "Equal Ressources",
            period: "",
            duration: "",
            web: "",
            carrier: "",
            partners:
                "",
            budget: "",
            images: [{id: 0, legend: "Logo equal", photo: equal, alt: "logo de equal"}],
            excerpt:                                
            "Programme de formation et qualification trinational de production d'outils d'évaluation et de reconnaissances à l'echelle européenne/basé sur les acquis d'apprentissage. \n"
        }
    },
    {
        id: 18,
        url: "",
        name: "Lucus",
        activity: "Lucus",
        slug: "",
        image: lucus,
        details: {}
    },
    {
        id: 19,
        url: "",
        name: "Demether",
        activity: "Demether",
        slug: "Demether",
        image: interreg,
        details: {
            project_name: "Demether",
            programme: "Demether",
            period: "",
            duration: "",
            web: "",
            carrier: "",
            partners:
                "",
            budget: "",
            images: [{id: 0, legend: "Logo interreg", photo: interreg, alt: "logo de interreg"}],
            excerpt:
            "Programmes pédagogiques franco allemands, doter les etablissements enseignement agricole d'outils d'appui pour les personnes en situation de handicap. \n"
        }
    },
    {
        id: 20,
        url: "",
        name: "Terra",
        activity: "Terra",
        slug: "",
        image: terra,
        details: {}
    },
    {
        id: 21,
        url: "",
        name: "Equideha",
        activity: "Equideha",
        slug: "equideha",
        image: equideha,
        details: {
            project_name: "Equideha",
            programme: "Equideha",
            period: "",
            duration: "",
            web: "",
            carrier: "",
            partners:
                "",
            budget: "",
            images: [{id: 0, legend: "Logo equideha", photo: equideha, alt: "logo de equideha"}],
            excerpt:
            
            "Creation d'emplois autour des métiers de la traction animale. \n"
            +
            "Realisation de travaux de balayage de pistes cyclables,ramassage des dechets plasics en ile de France et en alsace. \n"
            +
            " Realisation de referentiels de compétences autour du Galop 5 et 6. \n"

        }
    },
    {
        id: 22,
        url: "",
        name: "Legaqual",
        activity: "Legaqual",
        slug: "legaqual",
        image: legaqual,
        details: {
            project_name: "Legaqual",
            programme: "Legaqual",
            period: "",
            duration: "",
            web: "",
            carrier: "",
            partners:
                "",
            budget: "",
            images: [{id: 0, legend: "Logo Legaqual", photo: legaqual, alt: "logo de Legaqual"}],
            excerpt:
            
            "Programme de formation a destination de publics vulnérables dans 5 métiers et branches/travail de referentiels avec les branches concernées afin de rendre les reconaissances accessibles au plus grand nombre. \n"            
        }
    },
];