import React from 'react';

// Import components
import soliversLogo from '../../assets/Solivers-hd.png';
import SocialFollow from "./SocialFollow";
import "./styles.css";

const Footer = () => {

    return (
        <div className="relative flex flex-col md:flex-row justify-between items-center px-6 bottom-0 bg-solivers-6 py-4">
            <div className="w-2/12 mb-8  ">
                <img src={soliversLogo} className="" alt=""/>
            </div>
            <div className="w-8/12 mb-8 md:mb-0 mr-2 ml-2">
                <p className="text-center text-xs">
                    Solivers
                    <br />
                    SCIC au capital social variable de 91 000€
                    <br />
                    Num TVA FR63892229782
                    <br />
                    <br />
                    Nous contacter pour les conditions générales de vente
                    <br />
                    <br />
                    Directeur de la publication : Pierre Hoerter
                    <br />
                    <br />
                    Hébergeur: OVH sas, 2 rue Kellermann, 59100 Roubaix. tel: 1007
                    <br />
                    Nous n'utilisons pas de cookies sur ce site
                    <br />
                    <br />
                    <span className="text-xs">© Solivers 2022</span>
                </p>
            </div>
            {/*Social Media Network*/}
            <div className="w-full md:w-2/12 text-center text-sm">
                Nous suivre sur les réseaux sociaux :
                <br />
                <SocialFollow />
            </div>
        </div>
    );
};

export default Footer;