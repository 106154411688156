import React from 'react';

// Import components
import Footer from "../Commons/Footer";
import ParallaxC from '../Commons/ParallaxComp';

//Import images
import territoires from "../../assets/territoires-accueil.jpg";
import acteurlocal from "../../assets/acteurlocal.jpg";


const Home = () => {

    return (
        <>
            <main className="main">
                <section className="section">
                    < ParallaxC />
                    <article className="content text-solivers-5">
                        <div className="flex flex-col">

                            <div className="flex flex-col md:flex-row items-center">
                                <div className="flex flex-col lg:flex-grow md:w-6/12 lg:pr-24 md:pr-16 md:items-start md:text-left mb-10 md:mb-0 text-center">
                                    <h1 className="title-font sm:text-4xl text-3xl mb-8 font-regular text-solivers-5">
                                        Pour renforcer <span className="font-bold">l'inclusion</span>
                                        <br />sur le territoire
                                    </h1>
                                    <p className="leading-relaxed font-normal text-base">
                                        Solivers est une Société Coopérative d'Intérêt Collectif créée en 2012 pour mutualiser les moyens des Entreprises Apprenantes de Molsheim.
                                        <br />
                                        <br />
                                        Les Entreprises Adaptées et d'Insertion hébergées proposent des prestations dans les secteurs des métiers de bouche et de l'entretien des espaces verts.
                                        <br />
                                        <br />
                                        Solivers apporte des appuis pour des entreprises apprenantes de l'économie sociale et solidaire.
                                        La SCIC propose des services d'accompagnement global (administratif, comptabilité, informatique, juridique, formation, ...)
                                        <br />
                                        <br />
                                        L'entreprise accompagne les structures dans le développement de leurs projets. Elle aide également des porteurs de projets à développer leurs activités d'innovations sociales.
                                        Une équipe chez Solivers est dédiée pour les projets d'ingénierie de l'inclusion. Elle réalise notamment des outils didactiques pour les tuteurs / formateurs et les salariés engagés dans des parcours de formations qualifiantes.
                                    </p>
                                </div>
                                <div className="lg:max-w-sm lg:w-full md:w-6/12 w-5/6">
                                    <img className="object-cover object-center rounded-tl-full rounded-tr-full" alt="Nos territoires" src={territoires} />
                                </div>
                            </div>

                            <div className="flex flex-col-reverse md:flex-row items-center mt-24">
                                <div className="lg:max-w-sm lg:w-full md:w-6/12 w-5/6">
                                    <img className="object-cover object-center rounded-full" alt="vignoble alsacien" src={acteurlocal} />
                                </div>
                                <div className="flex flex-col lg:flex-grow md:w-6/12 lg:pl-24 md:pl-16 md:items-start md:text-left mb-10 md:mb-0 text-center">
                                    <h1 className="title-font sm:text-4xl text-3xl mb-8 font-regular text-solivers-5">
                                        Solivers, un acteur <span className="font-bold">local</span>
                                    </h1>
                                    <p>
                                        Solivers est une société coopérative d’interet collectif qui œuvre dans l’appui aux structures qui accompagnent des publics en situation de vulnérabilité.
                                        <br />
                                        Solivers accompagne notamment des structures d’insertion et adaptées, propose des méthodologies apprenantes aux structures qui y font appel.
                                        <br />
                                        La coopérative a déployé des outils d’accompagnement des publics cibles mais également à destination des employeurs. Solivers vise la révélation d’ecosystèmes territoriaux symbiotiques. Ainsi les logiques territoriales sont au cœur de l’approche et des accompagnements. 
                                        <br />
                                        La participation de tous les acteurs est promue dans les missions dans lesquelles la coopérative s’engage.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </article>
                </section>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
        </>
    );
};

export default Home;
