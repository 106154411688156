import React from 'react';
import { useNavigate } from "react-router-dom";

//Import icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const NotFound = ({ link, text }) => {
    const navigate = useNavigate();

    return (

    <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
    <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
            <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
                <span className="sr-only">Error</span>404
            </h2>            
            <p className="text-2xl font-semibold md:text-3xl">Page non trouvée</p>            
            <br />
            <button 
                onClick={() => navigate(link)}               
                className="border-solivers-2 margin hover:bg-solivers-5 hover:border-solivers-5 hover:text-white border text-solivers-2 rounded px-2 pb-px pt-1 mb-4 -mt-5"
            >
                <FontAwesomeIcon
                    className="inline mr-1 pt-px"
                    icon={faArrowLeft}
                />
                <p className="inline">{text}</p>
            </button>
        </div>
    </div>
    </section>
    
)};

export default NotFound;