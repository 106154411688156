import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// Import icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faConciergeBell,
    faHandsHelping,
    faRulerCombined,
    faUmbrella
} from "@fortawesome/free-solid-svg-icons";
import {
    faCompass
} from "@fortawesome/free-regular-svg-icons";

// Import components
import Footer from "../Commons/Footer";
import ParallaxC from '../Commons/ParallaxComp';
import AlertDialog from '../Commons/Dialog';


const advantages = [
    {
        id: 0,
        x: 5,
        y: -5,
        icon: faUmbrella,
        title: "Hébergement d'entreprises de l'ESS",
        text: "Location de bureaux, salles de réunions",
    },
    {
        id: 1,
        x: 3,
        y: 7,
        icon: faConciergeBell,
        title: "Services aux entreprises de l’ESS",
        text: "Services administratifs, comptabilité, informatique, juridique, formation...",
    },
    {
        id: 2,
        x: -5,
        y: -8,
        icon: faCompass,
        title: "Accompagnement de projets d’innovations sociales",
        text: "Simplification des démarches, accompagnement et conseils en développement",
    },
    {
        id: 3,
        x: -8,
        y: 3,
        icon: faHandsHelping,
        title: "Ingénierie de l’inclusion",
        text: "Formation au poste de travail, outils didactiques",
    },
    {
        id: 4,
        x: -8,
        y: 0,
        icon: faRulerCombined,
        title: "Étude du poste de travail",
        text: "Préconisations en ergonomie, aménagement de poste",
    },
]

const Prestations = () => {

    const [state, setState] = useState({
        open: false,
        errEmail: false,
        errTel: false,
        loading: false,
        data: null,
        openErr: false,
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur" || "onSubmit"
    });
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    
    const handleClose = (event, reason) => { 
       
        if (reason === 'clickaway') {
          return;
        }
        setState(prevState =>({ ...prevState, open: false}));
        setState(prevState =>({ ...prevState, openErr: false}));
    };
    
    
    function checkEmail(email){
        // eslint-disable-next-line
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function checkPhone(num){
        if(num.indexOf('+33')!==-1) num = num.replace('+33', '0');
        var re = /^0[1-9]\d{8}$/;
        return re.test(num);
    }

    function onSubmit(data) {
        if(!checkEmail(data.email)){
            setState(prevState =>({ ...prevState, errEmail: true}));
        }   
        else if(!checkPhone(data.phone)){
            setState(prevState =>({ ...prevState, errTel: true}));
        } 
        else{
            setState(prevState =>({ ...prevState, data: data, loading: true}));
        }
    }

    useEffect(() => {
        if (state.loading) {

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
    
            fetch('https://solivers.fr/prestations/index.php', {
                method: 'POST',
                headers: myHeaders,
                cache: 'default',
                body: JSON.stringify(state.data)
            })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                }
            })
            .then(json => {
                if(json.return === "done"){
                    document.getElementById("form").reset();
                    setState(prevState =>({ ...prevState, loading: false, open: true }));
                }
                else{
                    setState(prevState =>({ ...prevState, loading: false, openErr: true }));
                }
            })
            .catch(e => {
                setState(prevState =>({ ...prevState, loading: false, openErr: true }));
            });
        }
    }, [state.loading]);


    return (
        <>
            <main className="main">
                <section className="section">
                < ParallaxC />
                    <article className="content">
                        <div className="container flex flex-col md:flex-row mx-auto text-solivers-5">
                            <div className="w-full md:w-6/12 flex flex-col w-full mb-16">
                                <div>
                                    <h2 className="sm:text-5xl text-3xl title-font mb-8">
                                        Nos <span className="font-bold">prestations</span>
                                    </h2>
                                    <p className="leading-relaxed font-normal text-base">
                                        Solivers apporte des appuis pour des entreprises apprenantes de l'économie
                                        sociale et solidaire.
                                        <br/>
                                        La SCIC propose des services d'accompagnement global (administratif,
                                        comptabilité, informatique , juridique, formation, ...)
                                        <br/>
                                        L'entreprise accompagne les structures dans le développement de leurs projet.
                                        Elle aide également des porteurs de projets à développer leurs activités
                                        d'innovations sociales.
                                        <br/>
                                        Une équipe chez Solivers est dédiée pour les projets d'ingénierie de
                                        l'inclusion. elle réalise notamment des outils didactiques pour les
                                        tuteurs/formateurs et les salariés engagés dans des parcours de formations
                                        qualifiantes.
                                    </p>
                                </div>
                                <div className="flex flex-col justify-start mt-10">
                                    {advantages.map(advantage => (
                                        <PrestationsAdvantages
                                            key={advantage.id}
                                            advantage={advantage}
                                        />
                                    ))}
                                </div>
                            </div>
                           <div className="w-full md:w-6/12 ml-0 md:ml-14">
                               <div className="mt-3 md:mt-20">
                                   <h3 className="text-3xl">
                                       Demande de <span className="font-bold">devis </span>
                                       <span className="italic text-sm">(</span>
                                       <span className="font-bold text-2xl text-solivers-2 align-text-bottom">&nbsp; * </span>
                                       <span className="italic text-sm">requis )</span>
                                   </h3>
                               </div>
                               <form id='form' className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
                                   <div className="w-full mt-10">
                                       <label className="block w-full text-solivers-5 mb-1">
                                           Raison sociale <span className="font-bold text-lg text-solivers-2 align-text-bottom"> * </span>
                                       </label>
                                       <input
                                           className={`${
                                               errors.rs ? "border border-rose-700 rounded p-1" : ""
                                           }  w-full bg-solivers-0 rounded shadow-lg py-1 px-2`}
                                           type="text"
                                           placeholder="Raison sociale"
                                           {...register("rs", {required: true})}
                                       />
                                       {errors.rs && (
                                           <p className="text-rose-700 italic font-bold text-sm mt-1">
                                               Veuillez indiquer votre raison sociale
                                           </p>
                                       )}
                                   </div>
                                   <div className="w-full mt-10">
                                       <label className="block w-full text-solivers-5 mb-1">
                                           Description de votre société
                                       </label>
                                       <textarea
                                           className={`${
                                               errors.company ? "border border-rose-700 rounded p-1" : ""
                                           } block w-full bg-solivers-0 rounded shadow-lg py-1 px-2`}
                                           placeholder="Description courte de votre société"
                                           {...register("company", {})}
                                       />
                                   </div>
                                   <div className="w-full mt-10">
                                       <label className="block w-full text-solivers-5 mb-1">
                                           Description de vos besoins <span className="font-bold text-lg text-solivers-2 align-text-bottom"> * </span>
                                       </label>
                                       <textarea
                                           className={`${
                                               errors.description ? "border border-rose-700 rounded p-1" : ""
                                           } block w-full bg-solivers-0 rounded shadow-lg py-1 px-2`}
                                           placeholder="Description courte de vos besoins"
                                           {...register("description", {required: true})}
                                       />
                                       {errors.description && (
                                           <p className="text-rose-700 italic font-bold text-sm mt-1">
                                               Veuillez nous décrire vos besoins
                                           </p>
                                       )}
                                   </div>
                                   <div className="w-full mt-10">
                                       <label className="block w-full text-solivers-5 mb-1">
                                           Nom du contact <span className="font-bold text-lg text-solivers-2 align-text-bottom"> * </span>
                                       </label>
                                       <input
                                           className={`${
                                               errors.name ? "border border-rose-700 rounded p-1" : ""
                                           } block w-full bg-solivers-0 rounded shadow-lg py-1 px-2`}
                                           type="text"
                                           placeholder="Nom du contact"
                                           {...register("name", {required: true})}
                                       />
                                       {errors.name && (
                                           <p className="text-rose-700 italic font-bold text-sm mt-1">
                                               Veuillez nous indiquer le nom complet de votre contact
                                           </p>
                                       )}
                                   </div>
                                   <div className="w-full mt-10">
                                       <label className="block w-full text-solivers-5 mb-1">
                                           Email <span className="font-bold text-lg text-solivers-2 align-text-bottom"> * </span>
                                       </label>
                                       <input
                                           className={`${
                                               errors.email ? "border border-rose-700 rounded p-1" : ""
                                           } block w-full bg-solivers-0 rounded shadow-lg py-1 px-2`}
                                           type="text"
                                           placeholder="Email de contact"
                                           onInput={() => 
                                            setState(prevState =>({ ...prevState, errEmail: false}))
                                            }
                                           {...register("email", {required: true})}
                                       />
                                       {errors.email && (
                                           <p className="text-rose-700 italic font-bold text-sm mt-1">
                                               Veuillez nous indiquer votre email
                                           </p>
                                       )}
                                       {state.errEmail && (
                                            <p className="text-rose-700 italic font-bold text-sm mt-1">
                                                Adresse e-mail non valide
                                            </p>
                                        )}
                                   </div>
                                   <div className="w-full mt-10">
                                       <label className="block w-full text-solivers-5 mb-1">
                                           Téléphone <span className="font-bold text-lg text-solivers-2 align-text-bottom"> * </span>
                                       </label>
                                       <input
                                           className={`${
                                               errors.phone ? "border border-rose-700 rounded p-1" : ""
                                           } block w-full bg-solivers-0 rounded shadow-lg py-1 px-2`}
                                           type="tel"
                                           placeholder="Numéro de téléphone de contact"
                                           onInput={() => 
                                            setState(prevState =>({ ...prevState, errTel: false}))
                                            }
                                           {...register("phone", {required: true})}
                                       />
                                       {errors.phone && (
                                           <p className="text-rose-700 italic font-bold text-sm mt-1">
                                               Veuillez nous indiquer un numéro de téléphone où nous pouvons vous contacter
                                           </p>
                                       )}
                                       {state.errTel && (
                                            <p className="text-rose-700 italic font-bold text-sm mt-1">
                                                Numéro de téléphone non valide
                                            </p>
                                        )}
                                   </div>
                                   <div
                                       className={`${
                                           errors.rgpd ? "border border-rose-700 rounded p-2" : ""
                                       } flex flex-row items-center justify-between mt-10 text-sm `}
                                   >
                                       <label className="block rgpd-check border-solivers-0">
                                           <input
                                               type="checkbox"
                                               name="gdpr"
                                               {...register("rgpd", { required: true })}
                                           />
                                           <span className="checkmark"></span>
                                       </label>
                                       <span className="font-bold text-lg text-solivers-2 align-text-bottom"> * </span>
                                       <p className="block break-words ml-5">
                                           En soumettant ce formulaire, j’accepte que les informations saisies soient exploitées par Solivers et ses partenaires dans le cadre de la demande de contact et de la relation commerciale qui peut en découler.
                                       </p>
                                   </div>
                                   {errors.rgpd && (
                                       <p className="text-rose-700 font-bold italic text-sm pb-4 mt-1">
                                           Vous devez accepter la rgpd
                                       </p>
                                   )}
                                    { !state.loading && (<button
                                    id='sendButton'
                                    className="mx-auto mt-5 px-2 py-2 bg-solivers-5 text-solivers-0 rounded shadow-lg"
                                    type="submit"                                                    
                                    >
                                    Envoyer ma demande
                                    </button>)}                                                  
                                    { state.loading && (
                                    <LoadingButton
                                    sx={{marginRight: 39, marginLeft: 39, marginTop: 5, diplay: 'flex'}}                                    
                                    sendicon={<SendIcon />}
                                    loading={state.loading}
                                    loadingPosition="end"
                                    variant="contained"
                                    >
                                        <span>Envoi</span>
                                    </LoadingButton>
                                    )}
                               </form>
                           </div>
                        </div>
                    </article>
                </section>
            </main>
            <AlertDialog
                open={state.open}
                type={"Demande de devis envoyée avec succès !"} 
                onClose={handleClose}
                text={"Merci de l'intêret que vous nous portez. Nous vous recontacterons au plus vite."}           
            /> 
            <Snackbar open={state.openErr} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Un problème est survenu, veuillez réessayer plus tard.
                </Alert>
            </Snackbar>
            <footer className="footer">
                <Footer />
            </footer>
        </>
    );
};

const PrestationsAdvantages = ({ advantage }) => {

    return (
        <>
            <div className="w-full flex flex-col md:flex-row items-center mb-10">
                <div className="w-full md:w-2/12 relative h-12 w-12 md:w-16 md:h-16">
                    <div className="absolute flex place-content-center h-12 w-12 md:w-16 md:h-16 rounded-full bg-solivers-5 z-50">
                        <FontAwesomeIcon icon={advantage.icon} className="absolute h-full mx-auto text-3xl text-solivers-0"/>
                    </div>
                    <div
                        style={{top: advantage.x, left: advantage.y }}
                        className="absolute h-12 w-12 md:w-16 md:h-16 border border-solivers-6 rounded-full z-0"
                    />
                </div>
                <div className=" w-full md:w-10/12 mt-4 md:mt-0 ml-6">
                    <h4 className="break-words font-bold text-xl text-center md:text-left">{advantage.title}</h4>
                    <p className="break-words text-center md:text-left">{advantage.text}</p>
                </div>
            </div>
        </>
    );
};

export default Prestations;