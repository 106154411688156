import * as React from "react";
import {
    Routes,
    Route,
    Outlet,
    Navigate,
} from "react-router-dom";

import "./App.css";

//Import Components
import Home from './Components/Home';

import BeneficiariesList from './Components/Beneficiaries/BeneficiariesList';
import BeneficiaryDetails from './Components/Beneficiaries/BeneficiaryDetails';
import Prestations from './Components/Prestations';
import About from './Components/About';
import Contact from './Components/Contact';
import ProjectsList from './Components/Projects/ProjectsList';
import ProjectDetails from './Components/Projects/ProjectDetails';
import Nav from './Components/Commons/Nav';
import NotFound from "./Components/NotFound";


// Import Data
import beneficiariesData from './data/beneficiaries.js';
import projectsData from './data/projects.js';

const App = () => {
    return (
        <>
            <Routes>
                <Route element={ <Layout /> }>
                    <Route index path="/accueil" element={ <Home/> }/>                    
                    <Route index path="beneficiaires" element={<BeneficiariesList beneficiaries={ beneficiariesData } />}/>
                    <Route path="beneficiaires/:beneficiarySlug" element={ <BeneficiaryDetails /> } />                    
                    <Route path="prestations" element={ <Prestations /> } />                    
                    <Route index path="projets" element={ <ProjectsList projects={ projectsData } /> } />
                    <Route path="projets/:projectSlug" element={ <ProjectDetails /> } />                    
                    <Route path="a-propos" element={ <About /> } />
                    <Route path="contact" element={ <Contact /> } />
                    <Route
                        path="*"
                        element={ <Navigate to="/accueil" /> }
                    />
                    <Route path="*" component={NotFound} />
                </Route>
            </Routes>
        </>
    );
}

const Layout = () => {
    return (
        <>
            <Nav />
            <main className="relative">
                <Outlet />
            </main>
        </>
    );
};


export default App;
