import logostierkopf from '../assets/logostierkopf.jpg';
import logomainverte from '../assets/logomainverte.jpg';
import logobistrots from '../assets/logobistrots.jpg';
import logoinovhand from '../assets/logoinovhand.jpg';
import logocomform from '../assets/logocomform.jpg';
import logoavs from '../assets/logoavs.jpg'
import logodisco from '../assets/logodisco.png';
import la_main_verte from '../assets/la-main-verte.jpg';
import stierkopf from '../assets/stierkopf.jpg';
import avs from '../assets/avs.jpg';
import discovia from '../assets/discovia.jpg';
import inovhand from '../assets/inovhand.jpg';
import locavore from '../assets/locavore.jpg';
import geluculus from '../assets/geluculus.jpg';
import comform from '../assets/comform.jpg';
import bistrots from '../assets/bistrots.jpg';
import desherbage from '../assets/desherbage.jpg';
import vendanges from '../assets/vendanges.jpg';
import panoramique from '../assets/panoramique.jpg';
import vignoble from '../assets/vignoble.jpg';
import vignoble2 from '../assets/vignoble2.jpg';
import abeille from '../assets/abeille.jpg';
import camion from '../assets/camion.jpg';
import voitures from '../assets/voitures.jpg';
import repas from '../assets/repas.jpg';
import plateau from '../assets/plateau.jpg';
import cocktails from '../assets/cocktails.jpg';
import aperitifs from '../assets/aperitifs.jpg';
import locaproduits from '../assets/locaproduits.jpg';
import juspomme from '../assets/juspomme.jpg';
import tonte from '../assets/tonte.jpg';
import luculus1 from '../assets/luculus1.jpg';
import luculus2 from '../assets/luculus2.jpg';
import bistrotsgourmands from '../assets/bistrotsgourmands.jpg';
import formationcomform from '../assets/formationcomform.jpg';

// eslint-disable-next-line
export default [
  {
    id: 1,
    name: "Discovia",
    activity: "Innovation informatique, plateforme numérique ZeroBarrier",
    slug: "discovia",
    image: discovia,
    details: {
      rs: "Discovia",
      statut: "SAS de l'ESS",
      creation: "2021",
      web: "http://www.discovia.fr",
      email: "formation@discovia.fr",
      address: "17 rue de la Boucherie",
      postal_code: "67120",
      city: "Molsheim",
      phone: "09 78 80 14 13",
      activity: "Structurer et valoriser la montée en compétence des salariés.",
      agrements: "",
      workers: "8",
      images: [
        {id: 0, legend: "Logo discovia", photo: logodisco, alt: "logo de Discovia"},
      ],
      excerpt: "Discovia vous accompagne pour structurer et valoriser la montée en compétence de vos salariés au poste de travail."
    }
  },
  {
    id: 2,
    name: "La main verte",
    activity: "Gestion des espaces verts",
    slug: "la-main-verte",
    image: la_main_verte,
    details: {
      rs: "La main verte",
      statut: "Association de droit local",
      creation: "1993",
      web: "",
      email: "main-verte@wanadoo.fr",
      address: "46 route Ecospace",
      postal_code: "67120",
      city: "Molsheim",
      phone: "03 88 38 15 65",
      activity: "Entretien des espaces verts",
      agrements: "Entreprise adaptée",
      workers: "18",
      images: [
        {id: 0, legend: "Logo 'la main verte'", photo: logomainverte, alt: "logo de la main verte"},
        {id: 1, legend: "Désherbage en cours", photo: desherbage, alt: "agent des espaces verts en cours de désherbage"}
      ],
      excerpt: "La Main Verte est née en 1993 de la rencontre entre des agriculteurs, des structures d'aide aux parents de personnes handicapées, des représentants de tuteurs et de la Direction du Travail.\n" +
          "\n" +
          "L'idée de départ a été de faire appel à des personnes en situation de handicap et sans emploi pour répondre à des besoins de main d'oeuvre agricole saisonnière.\n" +
          "\n" +
          "L'Entreprise Adaptée intervient aujourd'hui pour l'entretien des espaces verts et des surfaces minérales des particuliers, des entreprises et des collectivités\n"
    }
  },
  {
    id: 3,
    name: "Stierkopf Environnement",
    activity: "Viticulture, maraîchage, prestations environnementales.",
    slug: "domaine-stierkopf",
    image: stierkopf,
    details: {
      rs: "Le Relais Stierkopf Environnement",
      statut: "SARL",
      creation: "2009",
      web: "",
      email: "relais.stierkopf@orange.fr",
      address: "46 route Ecospace",
      postal_code: "67120",
      city: "Molsheim",
      phone: "03 88 38 15 65",
      activity: "Viticulture, maraîchage, arboriculture, activités agricoles, activités environnementales.",
      agrements: "Entreprise adaptée",
      workers: "12",
      images: [
        {id: 0, legend: "Logo 'Stierkopf Environnement'", photo: logostierkopf, alt: "logo de Stierkopf Environnement"},
        {id: 1, legend: "Du raisin Bio", photo: abeille, alt: "Abeille buvant dans un grain de raisin"},
        {id: 2, legend: "Les vendanges", photo: vendanges, alt: "Vendangeur versant des grappes de raisin fraîchement récoltées"},
        {id: 3, legend: "Le vignoble du Stierkopf", photo: vignoble, alt: "Vue du vignoble du Stierkopf"},
        {id: 4, legend: "Vue panoramique du vignoble", photo: panoramique, alt: "Vue panoramique du Vignoble du Stierkopf"},
        {id: 5, legend: "Allées de vignes du Stierkopf", photo: vignoble2, alt: "Une vue des rangées de vignes"}
      ],
      excerpt: "L'Entreprise Adaptée exploite un domaine viticole de 15 ha sur les coteaux de Molsheim, Mutzig et Dangolsheim." +
          "\n" +
          "Elle exerce également des activités de maraîchage. " +
          "\n" +
          "En plus de la production de vin, de jus de fruits et de légumes elle propose des prestations agricoles, d'entretien d'espaces verts et des prestations environnementales."
    }
  },
  {
    id: 4,
    name: "Traiteur AVS",
    activity: "Traiteur, buffets, plateaux conférences, plats à livrer.",
    slug: "traiteur-avs",
    image: avs,
    details: {
      rs: "Le Relais AVS",
      statut: "SARL",
      creation: "2007",
      web: "http://www.traiteur-avs.com",
      email: "contact@traiteur-avs.com",
      address: "46 route Ecospace",
      postal_code: "67120",
      city: "Molsheim",
      phone: "03 88 38 10 15",
      activity: "Traiteur, mariages, cocktails, buffets, repas assis, plateaux conférences, plats à livrer.",
      agrements: "Entreprise adaptée",
      workers: "21",
      images: [
        {id: 0, legend: "Logo 'AVS traiteur engagé'", photo: logoavs, alt: "logo du traiteur AVS"},
        {id: 1, legend: "Un camion de AVS", photo: camion, alt: "Camion de livraison AVS"},
        {id: 2, legend: "Les voitures AVS", photo: voitures, alt: "Les voitures de livraison AVS"},
        {id: 3, legend: "Les cocktails", photo: cocktails, alt: "Vue d'un cocktails préparé par le traiteur AVS"},
        {id: 4, legend: "Les apéritifs", photo: aperitifs, alt: "Vue d'un apéritif préparé par le traiteur AVS"},
        {id: 5, legend: "Les repas sur assiettes", photo: repas, alt: "Un exemple de repas sur assiettes"},
        {id: 5, legend: "Préparation de plateaux repas", photo: plateau, alt: "Un exemple de plateau repas"},
      ],
      excerpt: "AVS Traiteur est une Entreprise Adaptée qui développe des activités autour des métiers de bouche sur le territoire de Molsheim avec pour objectif l'inclusion en milieu ordinaire de personnes en situation de handicap."
    }
  },
  {
    id: 5,
    name: "Locavore",
    activity: "Produits locaux",
    slug: "locavore",
    image: locavore,
    details: {
      rs: "Fillière locavore",
      statut: "SARL",
      creation: "2001",
      web: "",
      email: "contact@solivers.fr",
      address: "46 route Ecospace",
      postal_code: "67120",
      city: "Molsheim",
      phone: "03 88 38 15 17",
      activity: "Produits locaux",
      agrements: "",
      workers: "1",
      images: [
        {id: 0, legend: "Produits locaux", photo: locaproduits, alt: "Une séléction de produits locaux"},
        {id: 1, legend: "Un exemple de produit local", photo: juspomme, alt: "Un exemple d'un produit local"},
      ],
      excerpt: "Filière locavore est une plateforme logistique de produits locaux dont l'objectif est le développement de circuits-courts pour les métiers de bouche."
    }
  },
  {
    id: 6,
    name: "Inov'hand",
    activity: "Services aux particuliers",
    slug: "inovhand",
    image: inovhand,
    details: {
      rs: "Inov'Hand",
      statut: "Association de droit local",
      creation: "2009",
      web: "",
      email: "contact@main-verte.org",
      address: "46 route Ecospace",
      postal_code: "67120",
      city: "Molsheim",
      phone: "03 88 38 15 65",
      activity: "Entretien des espaces verts pour les particuliers",
      agrements: "Entreprise Adaptée",
      workers: "4",
      images: [
        {id: 0, legend: "Logo Inov'Hand", photo: logoinovhand, alt: "Le logo de Inov'Hand"},
        {id: 1, legend: "Exemple de service de tonte", photo: tonte, alt: "Exemple de service de tonte de pelouse"},
      ],
      excerpt: "Inov'hand propose des services à domicile rendus par des personnes en situation de handicap.\n" +
          "\n" +
          "Sous l'impulsion de l'Adapei du Bas-Rhin et de la Main Verte se sont regroupées des personnes qualiifiées et des personnes morales désireuses de s'associer à cet ambicieux projet."
    }
  },
  {
    id: 7,
    name: "Ge Luculus",
    activity: "Groupement d'employeurs dans les métiers de bouche.",
    slug: "ge-luculus",
    image: geluculus,
    details: {
      rs: "GE LUCULUS",
      statut: "Groupement d'employeurs - Association de droit local",
      creation: "2013",
      web: "",
      email: "contact@solivers.fr",
      address: "46 route Ecospace",
      postal_code: "67120",
      city: "Molsheim",
      phone: "03 88 38 15 65",
      activity: "Groupement d'employeur dans les métiers de bouche",
      agrements: "",
      workers: "10",
      images: [
          {id: 0, legend: "Equipe en cuisine", photo: luculus1, alt: "Une équipe en cuisine"},
          {id: 1, legend: "Equipe en cuisine", photo: luculus2, alt: "Une équipe en cuisine sur un autre poste"},
      ],
      excerpt: "GE Luculus est un groupement d'employeurs dans les métiers de bouche"
    }
  },
  {
    id: 8,
    name: "Bistrots gourmands",
    activity: "Formations professionnelles dans les métiers de la bouche.",
    slug: "bistrots-gourmands",
    image: bistrots,
    details: {
      rs: "Les Bistrots Gourmands du Rhin",
      statut: "Association de droit local",
      creation: "2008",
      web: "",
      email: "contact@solivers.fr",
      address: "46 route Ecospace",
      postal_code: "67120",
      city: "Molsheim",
      phone: "03 88 38 15 65",
      activity: "Formation professionnelle dans les métiers de bouche",
      agrements: "Organisme de formation n°42670460267 Agrément CPNE-IH",
      workers: "0",
      images: [
        {id: 0, legend: "Logo bistrots gourmands", photo: logobistrots, alt: "Le logo des bistrots gourmand du Rhin"},
        {id: 1, legend: "Une formation", photo: bistrotsgourmands, alt: "Une formation"},
      ],
      excerpt: "Les Bistrots Gourmands du Rhin interviennent dans la formation professionnelle pour les métiers de bouche. L'association est agrée par la CNPE-IH."
    }
  },
  {
    id: 9,
    name: "ComForm",
    activity: "Solutions de communication au moyen de codes visuo-gestuels",
    slug: "comform",
    image: comform,
    details: {
      rs: "ComForm",
      statut: "SCIC",
      creation: "2014",
      web: "",
      email: "contact@scic-comform.eu",
      address: "46 route Ecospace",
      postal_code: "67120",
      city: "Molsheim",
      phone: "03 88 38 15 65",
      activity: "Solutions de communication au moyen de codes visuo-gestuels",
      agrements: "Organisme de formation",
      workers: "1",
      images: [
        {id: 0, legend: "Logo ComForm", photo: logocomform, alt: "Le logo de ComForm"},
        {id: 1, legend: "Une formation", photo: formationcomform, alt: "Une formation Comform"},
      ],
      excerpt: "ComForm est une société coopérative qui propose des solutions de communication au moyen de codes visuo-gestuels."
    }
  }
];