import React from 'react';
import { Link } from "react-router-dom";


//Import icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

// Import components
import Footer from "../Commons/Footer";
import ParallaxC from '../Commons/ParallaxComp';


const ProjectsList = ({ projects }) => {

    return (
        <>
            <main className="main">
                <section className="section">
                    < ParallaxC />
                    <article className="content">
                        <div className="container mx-auto text-solivers-5">
                            <div className="flex flex-col w-full mb-16">
                                <h2 className="sm:text-4xl text-2xl title-font">
                                    <span className="font-bold">Les projets</span> des entreprises bénéficiant
                                    <br />
                                    des services de la SCIC Solivers
                                </h2>
                            </div>
                            <div className="flex flex-wrap -m-2">
                                {projects.map(project => (
                                    <div key={project.id} className="relative flex flex-col items-center p-3 lg:w-1/3 md:w-1/2 h-108 mb-5 w-full">
                                        <div className="h-full w-full flex flex-col items-center bg-solivers-0 rounded shadow-lg">
                                            <img
                                                className="w-full h-52 mb-6 object-cover object-center flex-shrink-0 shadow-sm rounded-tr rounded-tl"
                                                src={project.image}
                                                alt={project.name}
                                            />
                                            <div className="flex-grow text-center">
                                                <h2 className="text-lg font-bold mb-4">
                                                    {project.name}
                                                </h2>
                                                <p className="text-sm px-4">
                                                    {project.activity}
                                                </p>
                                            </div>
                                        </div>
                                        {project.slug && (
                                            <Link
                                                className="absolute -bottom-2 px-3 py-2 bg-solivers-5 hover:bg-solivers-6 text-solivers-0 rounded shadow-lg"
                                                to={project.slug}
                                                state={{ project: project }}
                                            >
                                                <p>
                                                    En savoir plus
                                                </p>
                                            </Link>
                                        )}
                                        {project.url && (
                                            <a
                                                className="absolute -bottom-2 px-3 py-2 bg-solivers-5 hover:bg-solivers-6 text-solivers-0 rounded shadow-lg"
                                                href={project.url}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                            >
                                                <p>
                                                    En savoir plus
                                                    <FontAwesomeIcon className="ml-2 text-xs" icon={faExternalLinkAlt} />
                                                </p>
                                            </a>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </article>
                </section>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
        </>
    );
};

export default ProjectsList;