import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import CloseIcon from '@mui/icons-material/Close';




export default function AlertDialog({ open, type, onClose, text }) {
    const navigate = useNavigate();
    return (

        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {type}
            </DialogTitle>

            <DialogContent>

                <DialogContentText id="alert-dialog-description">
                    {text}
                </DialogContentText>

            </DialogContent>

            <DialogActions>

                <Button
                    style={{ backgroundColor: "#474239", color: "white", textTransform: "none" }}
                    onClick={() => navigate('/accueil')}>
                    <HomeIcon style={{ marginRight: 2 }} />
                    Page
                    d'accueil
                </Button>

                <Button
                    style={{ backgroundColor: "#474239", color: "white", textTransform: "none" }}
                    onClick={() => onClose()}
                >
                    <CloseIcon />
                    Fermer
                </Button>

            </DialogActions>

        </Dialog>
        
    );
}