import React from 'react';
import { Parallax } from "react-scroll-parallax";

// Import image
import round2 from "../../assets/round-2.png";
import outlineRound1 from "../../assets/outline-round-1.png";
import round1 from "../../assets/round-1.png";

const ParallaxC = () => {

    return (

        <figure id="parallax" className="image-container">

            <div style={{ position: "absolute", left: "-35%" }}>
                <Parallax y={[-25, 25]}>
                    <img
                        style={{ width: "700px", height: "700px" }}
                        src={round2}
                        alt=""
                    />
                </Parallax>
            </div>

            <div style={{ position: "absolute", right: "-20%", top: "20%" }}>
                <Parallax y={[-85, 35]}>
                    <img
                        style={{ width: '800px', height: '800px' }}
                        src={outlineRound1}
                        alt=""
                    />
                </Parallax>
            </div>

            <div style={{ position: "absolute", right: "-15%", top: "20%" }}>
                <Parallax y={[-55, 35]}>
                    <img
                        style={{ width: '500px', height: '500px' }}
                        src={round1}
                        alt=""
                    />
                </Parallax>
            </div>

        </figure>

    )
};

export default ParallaxC;