import React, { useState } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

const Carousel = ({ images }) => {
    const [current, setCurrent] = useState(0);
    const length = images.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    if (!Array.isArray(images) || images.length <= 0) {
        return null;
    }

    return (
        <>
            <section className="relative h-full flex justify-center items-center">
                {images.length > 1 && (
                    <>
                        <div
                            onClick={prevSlide}
                            className="absolute flex justify-center items-center cursor-pointer top-[46.5%] -left-[13px] h-7 w-7 bg-solivers-5 hover:bg-solivers-2 rounded-full z-50"
                        >
                                <FontAwesomeIcon
                                    icon={faChevronLeft}
                                    className='text-lg text-solivers-0 z-10 select-none mr-1 '
                                />
                        </div>
                        <div
                            onClick={nextSlide}
                            className="absolute flex justify-center items-center cursor-pointer top-[46.5%] -right-[13px] h-7 w-7 bg-solivers-5 hover:bg-solivers-2 rounded-full z-50"
                        >
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className='text-lg text-solivers-0 z-10 select-none ml-px '
                            />
                        </div>
                    </>
                )}
                {images.map((img, index) => {
                    return (
                        <div
                            className={index === current ? 'opacity-100 transition ease-in duration-300' : 'opacity-0 transition ease-in duration-300'}
                            key={index}
                        >
                            {index === current && (
                                <img
                                    key={img.id}
                                    src={img.photo}
                                    alt={img.alt}
                                    className='w-full h-auto object-contain object-center shadow-lg mx-auto rounded-lg'
                                />
                            )}
                        </div>
                    );
                })}
            </section>
            <div className="flex flex-row justify-center items-center-3 w-2/12 mx-auto">
                {images.length > 1 && (
                    <div className="flex flex-row m-1">
                        {images.map((img, index) => (
                            <div
                                key={index}
                                onClick={() => setCurrent(index)}
                                className={`${current === index ? "bg-solivers-5" : "bg-solivers-2" } cursor-pointer h-4 w-4 rounded-full m-1`}/>
                            ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default Carousel;