import React from 'react';

const About = () => {

    return (
        <div>page About</div>
    )
}

export default About;
