import React, {Fragment} from 'react';
import { Popover, Transition } from '@headlessui/react'
import { NavLink, useResolvedPath } from "react-router-dom";

// Import icon
import {
    faBars,    
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import assets
import logoSolivers from "../../assets/Solivers-hd.png";

const menuEntries = [
    {
        id: 1,
        name: "Accueil",
        href: "/accueil",
        tooltipTitle: "Accueil du site"
    },
    {
        id: 2,
        name: "Bénéficiaires",
        href: "/beneficiaires",
        tooltipTitle: "Les bénéficiaires"
    },
    {
        id: 3,
        name: "Prestations",
        href: "/prestations",
        tooltipTitle: "Nos prestations"
    },
    {
        id: 4,
        name: "Projets",
        href: "/projets",
        tooltipTitle: "Nos projets"
    },
    {
        id: 6,
        name: "Contact",
        href: "/contact",
        tooltipTitle: "Nous contacter"
    }
];

const Nav = () => {

    return (
        <>
            {/* ---------------------- MENU ------------------------- */}
            <div className="nav">
                <Popover
                    className="relative w-full"
                >
                    <div className="flex justify-between items-center py-3 px-6 md:space-x-10">
                        <div className="w-6/12 sm:w-2/12">
                            <NavLink to="/">
                                <img
                                    className="mb-1"
                                    src={logoSolivers}
                                    alt="retour à l'accueil"
                                />
                            </NavLink>
                        </div>

                        {/* ---------------------- MENU BUTTON WHEN MOBILE ------------------------- */}
                        <div className="-mr-2 -my-2 md:hidden">
                            <Popover.Button className="p-2 inline-flex items-center justify-center text-solivers-5">
                                <span className="mr-4 self-center">Menu</span>
                                <FontAwesomeIcon className="self-center mt-1" icon={faBars} />
                            </Popover.Button>
                        </div>

                        {/* ---------------------- MENU BUTTON WHEN DESKTOP ------------------------- */}
                        <div className="hidden md:w-8/12 md:justify-end md:flex md:mt-4">
                            {menuEntries.map(menuItem =>
                                <div
                                    key={menuItem.id}
                                    className="xl:mr-8 lg:mr-6 sm:mr-2 sm:text-sm items-center"
                                >
                                    <CustomLink
                                        to={menuItem.href}
                                        mobile={false}
                                        title={menuItem.tooltipTitle}
                                    >
                                        <p className="whitespace-nowrap">{menuItem.name}</p>
                                    </CustomLink>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* ----------------------- INSIDE POPOVER PANEL WHEN MOBILE ----------------------- */}
                    <Transition
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute inset-x-0 p-2 top-0 transition transform origin-top-right md:hidden"
                        >
                            <div className="rounded-lg shadow-lg ring-1 ring-solivers-5 ring-opacity-5 bg-white divide-y-2">
                                <div className="pt-5 pb-6 px-5">
                                    <div className="flex justify-between">
                                        <div>
                                            <img
                                                className="h-10 w-auto mb-4"
                                                src={logoSolivers}
                                                alt="solivers"
                                            />
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button className="bg-white px-3 py-2 mb-4 inline-flex items-center justify-center text-solivers-5 border-solivers-5 border border-opacity-30 rounded focus:outline-none ">
                                                <span className="sr-only">Fermer</span>
                                                <FontAwesomeIcon
                                                    icon={faTimes}
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="mt-6">
                                        <nav className="flex flex-col justify-center">
                                            {menuEntries.map(menuItem => (
                                                <Popover.Button key={menuItem.id}>
                                                    <CustomLink
                                                        mobile={true}
                                                        to={menuItem.href}
                                                        title={menuItem.tooltip}
                                                    >
                                                        <span className="text-base font-medium text-solivers-5">
                                                          {menuItem.name}
                                                        </span>
                                                    </CustomLink>
                                                </Popover.Button>
                                            ))}
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>
            </div>
        </>
    );
};

const CustomLink = ({ children, to, mobile, ...props }) => {
    // eslint-disable-next-line
    let resolved = useResolvedPath(to);

    let match = window.location.pathname.includes(to)

    return (
        <>
            {match ? (
                <div className="w-fit mx-auto">
                    <div className="relative px-3 py-4">
                        <button className="relative font-medium text-solivers-5 z-50">
                            <NavLink
                                to={to}
                                {...props}
                            >
                                {children}
                            </NavLink>
                        </button>
                        <div className={`${mobile === true ? "top-4 -mt-px" : "top-3"} absolute left-1 h-7 w-7 rounded-full bg-menu`} />
                    </div>
                </div>
            ) : (
                <div className="px-3 py-4">
                    <button className="relative font-medium text-solivers-5 z-50">
                        <NavLink
                            to={to}
                            {...props}
                        >
                            {children}
                        </NavLink>
                    </button>
                </div>
            )}
        </>
    );
}

export default Nav;