import React, { useState, useEffect } from 'react';
import { useNavigate, useParams} from "react-router-dom";

//Import icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";

// Import components
import Footer from "../Commons/Footer";
import Carousel from '../Commons/Carousel';
import beneficiaries from '../../data/beneficiaries';
import NotFound from '../NotFound';
import ParallaxC from '../Commons/ParallaxComp';


const BeneficiaryDetails = () => {
    const navigate = useNavigate();
    const { beneficiarySlug } = useParams();    
    const data = beneficiaries.find(b => b.slug === beneficiarySlug);

    const [state, setState] = useState({
        loading: true,
        error: false,
        name: '',
        excerpt: '',
        images: '',
        rs: '',
        statut: '',
        creation: '',        
        web: '',
        address: '',
        postal_code: '',
        city: '',
        phone: '',
        activity: '',
        agrements: '',        
        workers: '',
        email: '',
    });
      
    useEffect(() => {
        if (state.loading){
            if(data) {                
                setState({
                    ...state,
                    loading: false,
                    name: data.name,
                    excerpt: data.details.excerpt,
                    images: data.details.images,
                    rs: data.details.rs,
                    statut: data.details.statut,
                    creation: data.details.creation,
                    web: data.details.web,
                    address: data.details.address,
                    postal_code: data.details.postal_code,
                    email: data.details.email,
                    city: data.details.city,
                    phone: data.details.phone,
                    activity: data.details.activity,
                    agrements: data.details.agrements,
                    workers: data.details.workers,                    
                });
            }
            else {                
                setState({ ...state, loading: false, error: true });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);
    

    return (
        <>
            <main>
                <section className="section">                    
                    < ParallaxC />
                    <article className="content">
                        <div className="flex flex-col text-solivers-5">

                            <div className="flex md:flex-row flex-col items-center">
                            { !state.error &&(
                                <div className="w-full flex flex-col md:items-start md:text-left mb-10 md:-mb-3 items-center text-center">
                                    <button
                                        onClick={() => navigate(-1)}
                                        className="border-solivers-2 hover:bg-solivers-5 hover:border-solivers-5 hover:text-white border text-solivers-2 rounded px-2 pb-px pt-1 mb-4 -mt-5"
                                    >
                                        <FontAwesomeIcon
                                            className="inline mr-1 pt-px"
                                            icon={faArrowLeft}
                                        />
                                        <p className="inline">retour</p>
                                    </button>
                                    <h1 className="title-font sm:text-4xl text-3xl mb-7 font-regular text-solivers-5">
                                        <span className="font-bold">{state.name}</span>
                                    </h1>
                                    <p className="leading-relaxed font-normal text-base">
                                        {state.excerpt}
                                    </p>
                                </div>
                            )}
                            </div>

                            { !state.error && (<div className="container overflow-hidden w-full mx-auto bg-solivers-0 flex mt-4 md:mt-16 md:flex-row flex-col-reverse items-center shadow-lg rounded">

                                <div className="w-full md:w-6/12 flex flex-col md:items-center p-4 m-3">
                                    <Carousel images={state.images} />
                                </div>


                                <div className="w-full my-12 md:w-6/12 md:mr-10 ml-10 md:ml-0 p-2">
                                    <h5 className="text-solivers-5 text-2xl uppercase font-bold">{state.name}</h5>
                                    <p className="text-solivers-2 word-break">{state.rs} - {state.statut}</p>

                                    <hr className="border-b border-solivers-5 my-3"/>
                                    <div className="container py-2 mx-auto">
                                        <div className="-my-4 divide-y divide-solivers-1">

                                            {/*Creation*/}
                                            <div className="py-3 flex flex-wrap md:flex-nowrap">
                                                <div className="w-4/12">
                                                    <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                        Date de création
                                                    </span>
                                                </div>
                                                <div className="w-8/12 ml-0 md:ml-3">
                                                    <p className="leading-relaxed font-semibold text-sm">
                                                        {state.creation}
                                                    </p>
                                                </div>
                                            </div>

                                            {/*Web*/}
                                            {state.web && (
                                                <div className="py-3 flex flex-wrap md:flex-nowrap">
                                                    <div className="w-4/12">
                                                        <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                            Site web
                                                        </span>
                                                    </div>
                                                    <div className="w-8/12 ml-0 md:ml-3">
                                                        <p className="leading-relaxed underline underline-offset-1 font-bold text-sm">
                                                            <a href={state.web} target="_blank" rel="noopener noreferrer">{state.web}</a>
                                                            <FontAwesomeIcon className="ml-2 text-xs" icon={faExternalLinkAlt} />
                                                        </p>
                                                    </div>
                                                </div>
                                            )}

                                            {/*Email*/}
                                            <div className="py-3 flex flex-wrap md:flex-nowrap">
                                                <div className="w-4/12">
                                                    <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                        Email
                                                    </span>
                                                </div>
                                                <div className="w-8/12 ml-0 md:ml-3">
                                                    <p className="leading-relaxed font-semibold italic underline underline-offset-1 text-sm">
                                                        <a href={"mailto:" + state.email}>{state.email}</a>
                                                        <FontAwesomeIcon className="ml-2 text-xs" icon={faExternalLinkAlt} />
                                                    </p>
                                                </div>
                                            </div>

                                            {/*Adresse*/}
                                            <div className="py-3 flex flex-wrap md:flex-nowrap">
                                                <div className="w-4/12">
                                                    <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                        Adresse
                                                    </span>
                                                </div>
                                                <div className="w-8/12 ml-0 md:ml-3">
                                                    <p className="leading-relaxed font-semibold text-sm">
                                                        {state.address}
                                                        <br/>
                                                        {state.postal_code} {state.city}
                                                    </p>
                                                </div>
                                            </div>

                                            {/*Phone*/}
                                            <div className="py-3 flex flex-wrap md:flex-nowrap">
                                                <div className="w-4/12">
                                                    <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                        Téléphone:
                                                    </span>
                                                </div>
                                                <div className="w-8/12 ml-0 md:ml-3">
                                                    <p className="leading-relaxed font-semibold text-sm">
                                                        {state.phone}
                                                    </p>
                                                </div>
                                            </div>

                                            {/*Activites*/}
                                            <div className="py-3 flex flex-wrap md:flex-nowrap">
                                                <div className="w-4/12">
                                                    <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                        Activités
                                                    </span>
                                                </div>
                                                <div className="w-8/12 ml-0 md:ml-3">
                                                    <p className="leading-relaxed font-semibold text-sm">
                                                        {state.activity}
                                                    </p>
                                                </div>
                                            </div>

                                            {/*Agrements*/}
                                            {state.agrements && (
                                                <div className="py-3 flex flex-wrap md:flex-nowrap">
                                                    <div className="w-4/12">
                                                        <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                            Agréments:
                                                        </span>
                                                    </div>
                                                    <div className="w-8/12 ml-0 md:ml-3">
                                                        <p className="leading-relaxed font-semibold text-sm">
                                                            {state.agrements}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}

                                            {/*Workers*/}
                                            <div className="py-3 flex flex-wrap md:flex-nowrap">
                                                <div className="w-4/12">
                                                    <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                        Nombre de salariés
                                                    </span>
                                                </div>
                                                <div className="w-8/12 ml-0 md:ml-3">
                                                    <p className="leading-relaxed font-semibold text-sm">
                                                        {state.workers}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>)}
                            {state.error &&(
                                <div className="d-flex align-items-center justify-content-center" >
                                    <div style={{                                                    
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"                                                                                                                                                           
                                    }}>
                                        <NotFound
                                            link= "../../beneficiaires"
                                            text= "Retour à la liste des bénéficiaires"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </article>
                </section>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
        </>
    );
};

export default BeneficiaryDetails;

