import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';
import "./index.css";
import App from "./App";
import ScrollToTop from "./ScrollToTop";

ReactDOM.render(
    <BrowserRouter>
        <ParallaxProvider>
            <ScrollToTop />
            <App />
        </ParallaxProvider>
    </BrowserRouter>,
    document.getElementById("root")
);
