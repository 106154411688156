import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";

//Import icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

// Import components
import Footer from "../Commons/Footer";
import Carousel from '../Commons/Carousel';
import NotFound from '../NotFound';
import projects from '../../data/projects';
import ParallaxC from '../Commons/ParallaxComp';


const ProjectDetails = () => {
    const navigate = useNavigate();
    const { projectSlug } = useParams();
    const data = projects.find(p => p.slug === projectSlug);
    console.log(data);
    


    const [state, setState] = useState({
        loading: true,
        error: false,
        name: '',
        excerpt: '',
        images: '',
        project_name: '',
        programme: '',
        period: '',
        duration: '',
        web: '',
        carrier: '',
        partners: '',
        budget: '',
    });

    useEffect(() => {
        if (state.loading) {
            if (data) {
                setState({
                    ...state,
                    loading: false,
                    name: data.name,
                    excerpt: data.details.excerpt,
                    images: data.details.images,
                    project_name: data.details.project_name,
                    programme: data.details.programme,
                    period: data.details.period,
                    duration: data.details.duration,
                    web: data.details.web,
                    carrier: data.details.carrier,
                    partners: data.details.partners,
                    budget: data.details.budget,
                });
            }
            else {
                console.log("erreur");
                setState({ ...state, loading: false, error: true });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (

        <>
            <main>
                <section className="section">
                    < ParallaxC />
                    <article className="content">
                        <div className="flex flex-col text-solivers-5">
                            <div className="flex md:flex-row flex-col items-center">
                                {!state.error && (
                                    <div className="w-full flex flex-col md:items-start md:text-left mb-10 md:-mb-3 items-center text-center">
                                        <button
                                            onClick={() => navigate(-1)}
                                            className="border-solivers-2 hover:bg-solivers-5 hover:border-solivers-5 hover:text-white border text-solivers-2 rounded px-2 pb-px pt-1 mb-4 -mt-5"
                                        >
                                            <FontAwesomeIcon
                                                className="inline mr-1 pt-px"
                                                icon={faArrowLeft}
                                            />
                                            <p className="inline">retour</p>
                                        </button>
                                        <h1 className="title-font sm:text-4xl text-3xl mb-7 font-regular text-solivers-5">
                                            <span className="font-bold">{state.name}</span>
                                        </h1>
                                        <p className="leading-relaxed font-normal text-base">
                                            {state.excerpt}
                                        </p>
                                    </div>
                                )}
                            </div>
                            {!state.error && (<div className="container overflow-hidden w-full mx-auto bg-solivers-0 flex mt-4 md:mt-16 md:flex-row flex-col-reverse items-center shadow-lg rounded">

                                {state.images && (<div className="w-full md:w-6/12 flex flex-col md:items-center p-5 m-3">
                                    <Carousel images={state.images} />
                                </div>
                                )}


                                <div className="w-full my-12 md:w-6/12 md:mr-10 ml-10 md:ml-0 p-2">
                                    <h5 className="text-solivers-5 text-2xl uppercase font-bold">{state.project_name}</h5>
                                    <div className="container py-2 mx-auto">
                                        <hr className="border-b border-solivers-5 my-3" />
                                        <div className="-my-4 divide-y divide-solivers-1">

                                            {/*Progamme*/}
                                            <div className="py-3 flex flex-wrap md:flex-nowrap">
                                                <div className="w-4/12">
                                                    <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                        Programme
                                                    </span>
                                                </div>
                                                <div className="w-8/12 ml-0 md:ml-3">
                                                    <p className="leading-relaxed font-semibold text-sm">
                                                        {state.programme}
                                                    </p>
                                                </div>
                                            </div>

                                            {/*Periode*/}
                                            {state.period && (
                                                <div className="py-3 flex flex-wrap md:flex-nowrap">
                                                    <div className="w-4/12">
                                                        <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                            Periode
                                                        </span>
                                                    </div>
                                                    <div className="w-8/12 ml-0 md:ml-3">
                                                        <p className="leading-relaxed font-semibold text-sm">
                                                            {state.period}
                                                        </p>
                                                    </div>
                                                </div>
                                            
                                            )}

                                            {/*Duration*/}
                                            {state.duration && (
                                            <div className="py-3 flex flex-wrap md:flex-nowrap">
                                                <div className="w-4/12">
                                                    <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                        Durée
                                                    </span>
                                                </div>
                                                <div className="w-8/12 ml-0 md:ml-3">
                                                    <p className="leading-relaxed font-semibold text-sm">
                                                        {state.duration}
                                                    </p>
                                                </div>
                                            </div>

                                            )}

                                            {/*Web*/}
                                            {state.web && (
                                                <div className="py-3 flex flex-wrap md:flex-nowrap">
                                                    <div className="w-4/12">
                                                        <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                            Site web
                                                        </span>
                                                    </div>
                                                    <div className="w-8/12 ml-0 md:ml-3">
                                                        <p className="leading-relaxed underline underline-offset-1 font-bold text-sm">
                                                            <a href={state.web} target="_blank" rel="noopener noreferrer">{state.web}</a>
                                                            <FontAwesomeIcon className="ml-2 text-xs" icon={faExternalLinkAlt} />
                                                        </p>
                                                    </div>
                                                </div>
                                            )}

                                            {/*Carriere*/}
                                            {state.carrier && (
                                            <div className="py-3 flex flex-wrap md:flex-nowrap">
                                                <div className="w-4/12">
                                                    <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                        Porteur du projet
                                                    </span>
                                                </div>
                                                <div className="w-8/12 ml-0 md:ml-3">
                                                    <p className="leading-relaxed font-semibold text-sm">
                                                        {state.carrier}
                                                    </p>
                                                </div>
                                            </div>
                                            )}

                                            {/*Partners*/}
                                            {state.partners && (<div className="py-3 flex flex-wrap md:flex-nowrap">
                                                <div className="w-4/12">
                                                    <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                        Partenaires européens
                                                    </span>
                                                </div>
                                                <div className="w-8/12 ml-0 md:ml-3">
                                                    <p className="leading-relaxed font-semibold text-sm">
                                                        {state.partners}
                                                    </p>
                                                </div>
                                            </div>
                                            )}

                                            {/*Budget*/}
                                            {state.budget && (
                                                <div className="py-3 flex flex-wrap md:flex-nowrap">
                                                    <div className="w-4/12">
                                                        <span className="font-semibold text-xs text-solivers-2 uppercase">
                                                            Budget
                                                        </span>
                                                    </div>
                                                    <div className="w-8/12 ml-0 md:ml-3">
                                                        <p className="leading-relaxed font-semibold text-sm">
                                                            {state.budget}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>)}
                            {state.error && (
                                <div className="d-flex align-items-center margin-top justify-content-center" >
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <NotFound
                                            link="../../projets"
                                            text="Retour à la liste des projets"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </article>
                </section>
            </main>
            <footer className="footer">
                <Footer />
            </footer>
        </>
    );
};

export default ProjectDetails;
