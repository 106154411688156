import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// Import components
import Footer from "../Commons/Footer";
import ParallaxC from '../Commons/ParallaxComp';
import AlertDialog from '../Commons/Dialog';

const Contact = () => {

    const [state, setState] = useState({
        open: false,
        errEmail: false,
        loading: false,
        data: null,
        openErr: false,
    });
     
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur" || "onSubmit"
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setState(prevState =>({ ...prevState, open: false}));
        setState(prevState =>({ ...prevState, openErr: false}));
    };

    

    function checkEmail(email) {
        // eslint-disable-next-line
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    
    function onSubmit(data) {
        if (!checkEmail(data.email)) {
            setState(prevState =>({ ...prevState, errEmail: true}));
        }
        else {
            setState(prevState =>({ ...prevState, data: data, loading: true}));
        } 
    }

    useEffect(() => {
        if (state.loading) {

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
    
            fetch('https://solivers.fr/contact/index.php', {
                method: 'POST',
                headers: myHeaders,
                cache: 'default',
                body: JSON.stringify(state.data)
            })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                }
            })
            .then(json => {
                if(json.return === "done"){
                    document.getElementById("form").reset();
                    setState(prevState =>({ ...prevState, loading: false, open: true }));
                }
                else{
                    setState(prevState =>({ ...prevState, loading: false, openErr: true }));
                }
            })
            .catch(e => {
                setState(prevState =>({ ...prevState, loading: false, openErr: true }));
            });
        }
    }, [state.loading]);

    return (        
        <>        
            <main className="main">
                <section className="section">
                    < ParallaxC />
                    <article className="content text-solivers-5">
                        <h2 className="sm:text-5xl text-3xl font-bold mb-12">
                            Contact
                        </h2>
                        <section className="body-font relative">
                            <div className="container py-10 mx-auto">
                                <div className="relative w-full h-[500px] rounded-lg overflow-hidden p-12 flex items-end justify-start shadow-lg">
                                    <iframe width="100%" height="100%" className="absolute inset-0" frameBorder="0"
                                        title="map" marginHeight="0" marginWidth="0" scrolling="no"
                                        src="https://maps.google.com/maps?width=100%&amp;height=700&amp;hl=fr&amp;q=48.53512222083218,7.517662784961732&amp;ie=UTF8&amp;t=&amp;z=18&amp;iwloc=B&amp;output=embed"
                                        style={{ filter: "grayscale(.8) contrast(0.8) opacity(0.9)", border: 0 }} />

                                    {/*Adress Card*/}
                                    <div className="bg-solivers-0 relative flex flex-wrap py-6 rounded shadow-md">
                                        <div className="lg:w-1/2 px-6">
                                            <h2 className="title-font font-semibold text-solivers-2 tracking-widest text-xs">ADDRESSE</h2>
                                            <p className="leading-relaxed text-lg font-bold uppercase mb-3 mt-3">
                                                Solivers
                                            </p>
                                            <p className="leading-relaxed font-normal text-base font-semibold">
                                                46 Route Ecospace
                                                <br />
                                                67120 Molsheim
                                            </p>
                                        </div>
                                        
                                        <div className="lg:w-1/2 px-6 mt-4 md:mt-0">
                                            <h2 className="title-font font-semibold text-solivers-2 tracking-widest text-xs">TEL</h2>
                                            {/*  eslint-disable-next-line */}
                                            <a className="font-semibold leading-relaxed">03 88 38 15 65</a>
                                            <h2 className="title-font font-semibold text-solivers-2 tracking-widest text-xs mt-4">FAX</h2>
                                            <p className="font-semibold leading-relaxed">03 88 38 15 81</p>
                                            <h2 className="title-font font-semibold text-solivers-2 tracking-widest text-xs mt-4">EMAIL</h2>
                                            <p className="font-semibold leading-relaxed">contact@solivers.fr</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="container py-12 mx-auto">
                                <div className="text-center w-full mb-12">
                                    <h3 className="sm:text-3xl text-2xl font-medium title-font mb-2">
                                        Formulaire de <span className="font-bold">contact </span>
                                    </h3>
                                    <p className="mx-auto leading-relaxed text-sm mb-4">
                                        <span className="font-bold text-lg text-solivers-2 align-text-bottom"> * </span>
                                        requis
                                    </p>
                                </div>
                                <div className="w-11/12 mx-auto">
                                    <form
                                        id='form'
                                        onSubmit={handleSubmit(onSubmit)}
                                        method='POST'
                                        className="flex flex-wrap -m-2"
                                    >
                                        <div className="flex flex-wrap -m-2">
                                            <div className="pr-2 w-1/2">
                                                <div className="relative">
                                                    <label className="block w-full text-solivers-5 mb-1">
                                                        Nom
                                                        <span className="font-bold text-lg text-solivers-2 align-text-bottom"> * </span>
                                                    </label>
                                                    <input
                                                        className={`${errors.name ? "border border-rose-700 rounded p-1" : ""
                                                            }  w-full bg-solivers-0 rounded shadow-lg py-1 px-2`}
                                                        type="text"
                                                        placeholder="Nom"
                                                        {...register("name", { required: true })}
                                                    />
                                                    {errors.name && (
                                                        <p className="text-rose-700 italic font-bold text-sm mt-1">
                                                            Veuillez indiquer votre nom
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="pl-2 w-1/2">
                                                <div className="relative">
                                                    <label className="block w-full text-solivers-5 mb-1">
                                                        Email
                                                        <span className="font-bold text-lg text-solivers-2 align-text-bottom"> * </span>
                                                    </label>
                                                    <input
                                                        className={`${errors.email ? "border border-rose-700 rounded p-1" : ""
                                                            } block w-full bg-solivers-0 rounded shadow-lg py-1 px-2`}
                                                        type="mail"
                                                        onInput={() => 
                                                            setState(prevState =>({ ...prevState, errEmail: false}))
                                                        }

                                                        placeholder="Email de contact"
                                                        {...register("email", { required: true })}
                                                    />
                                                    {errors.email && (
                                                        <p className="text-rose-700 italic font-bold text-sm mt-1">
                                                            Veuillez nous indiquer votre email
                                                        </p>
                                                    )}
                                                    {state.errEmail && (
                                                        <p className="text-rose-700 italic font-bold text-sm mt-1">
                                                            Adresse e-mail non valide
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="w-full mt-5">
                                                <div className="relative">
                                                    <label className="block w-full text-solivers-5 mb-1">
                                                        Message <span
                                                            className="font-bold italic text-xs text-solivers-2 align-text-bottom"> ( optionnel ) </span>
                                                    </label>
                                                    <textarea
                                                        rows="5"
                                                        className={`${errors.message ? "border border-rose-700 rounded p-1" : ""
                                                            } block w-full bg-solivers-0 rounded shadow-lg py-1 px-2`}
                                                        placeholder="Votre message"
                                                        {...register("message", {})}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={`${errors.rgpd ? "border border-rose-700 rounded p-2" : ""
                                                    } flex flex-row items-center justify-between mt-10 text-sm `}
                                            >
                                                <label className="block rgpd-check border-solivers-0">
                                                    <input
                                                        type="checkbox"
                                                        name="gdpr"
                                                        {...register("rgpd", { required: true })}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <span className="font-bold text-lg text-solivers-2 align-text-bottom"> * </span>
                                                <p className="block break-words ml-5">
                                                    En soumettant ce formulaire, j’accepte que les informations saisies soient
                                                    exploitées par Solivers et ses partenaires dans le cadre de la demande de
                                                    contact et de la relation commerciale qui peut en découler.
                                                </p>
                                            </div>
                                            {errors.rgpd && (
                                                <p className="text-rose-700 font-bold italic text-sm pb-4 mt-1">
                                                    Vous devez accepter la rgpd
                                                </p>
                                            )}
                                            <div className="mx-auto mt-10">
                                                
                                                {!state.loading && (<button
                                                    id='sendButton'
                                                    className="rounded font-medium px-4 py-2 transition duration-300 ease-in-out mr-6 bg-solivers-5 hover:bg-solivers-6 text-solivers-0 shadow-lg"
                                                    type="submit"
                                                >
                                                    Envoyer ma demande
                                                </button> )}

                                                {state.loading && (
                                                    <LoadingButton
                                                        className="rounded font-medium px-4 py-2 transition duration-300 ease-in-out mr-6 bg-solivers-5 hover:bg-solivers-6 text-solivers-0 shadow-lg"
                                                        endIcon={<SendIcon />}
                                                        loading={state.loading}
                                                        loadingPosition="end"
                                                        variant="contained"
                                                    >
                                                        <span>Envoi</span>
                                                    </LoadingButton>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </article>
                </section>
            </main>
            <AlertDialog
                open={state.open}
                type={"Formulaire de contact envoyé avec succès !"}
                onClose={handleClose}
                text={"Merci d'avoir pris le temps de nous écrire, nous vous recontacterons dès que possible."}
            />
            <Snackbar open={state.openErr} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Un problème est survenu, veuillez réessayer plus tard.
                </Alert>
            </Snackbar>
            <footer className="footer">
                <Footer />
            </footer>
        </>
    );
};

export default Contact;
